import React, { useState, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ViewerToolbar from './ViewerToolbar';
import { ViewerContext } from './ViewerContext';
import { ClassroomContext } from '../ClassroomContext';

function Cards() {
    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState('');

    const {
        showTags,
        filteredCards,
        isRevealed, setIsRevealed,
        showQuestionFirst,
        mode
    } = useContext(ViewerContext);

    const { custom } = useContext(ClassroomContext);

    const closeModal = () => setShowModal(false);

    const visibleStyle = {};
    const hiddenStyle = {
        color: 'transparent'
    };

    const buttonStyle = {};
    if (custom && custom.buttonColour) {
        buttonStyle.backgroundColor = custom.buttonColour;
        buttonStyle.borderColor = custom.buttonColour;
    }

    const hiddenButtonStyle = {
        ...buttonStyle,
        visibility: 'hidden'
    };

    const clickRow = (index) => {
        const newIsRevealed = [...isRevealed];
        newIsRevealed[index] = !newIsRevealed[index];
        setIsRevealed(newIsRevealed);
    };

    const showNote = (e, note) => {
        setModalText(note);
        setShowModal(true);
        e.stopPropagation();
    };

    const isQuestionVisible = (index) => {
        return mode === 'match' || isRevealed[index] || showQuestionFirst[index];
    };

    const isAnswerVisible = (index) => {
        return mode === 'match' || isRevealed[index] || !showQuestionFirst[index];
    };

    const isQuestionRevealable = (index) => {
        return mode !== 'match' && !showQuestionFirst[index];
    };

    const isAnswerRevealable = (index) => {
        return mode === 'match' || showQuestionFirst[index];
    };

    const getBackText = (card, index) => {
        if (mode !== 'match' || isRevealed[index]) return card.back;
        return card.shuffledBack;
    };

    const getAnswerLetter = (card, index) => {
        if (mode !== 'match' || !isRevealed[index]) return String.fromCharCode(65 + index);
        return String.fromCharCode(65 + card.answerIndex);
    };

    const getAnswerTextClass = (index) => {
        if (mode !== 'match' || !isRevealed[index]) return '';
        return 'match-revealed-answer';
    };

    return (
        <>
            <ViewerToolbar />
            <Table bordered className="questions" size="sm">
                {mode === 'match' &&
                    <colgroup>
                        <col />
                        <col style={{width: '50%'}} />
                        <col />
                        <col style={{width: '50%'}} />
                    </colgroup>}
                <tbody>
                    {filteredCards.map((c, index) => (
                        <tr key={index}>
                            <th>{index + 1}</th>
                            <td style={isQuestionVisible(index) ? visibleStyle : hiddenStyle} onClick={() => clickRow(index)} className={isQuestionRevealable(index) ? 'revealable' : ''}>
                                {showTags &&
                                    <>
                                        {c.tags.filter(t => t.length).map(t => (
                                            <span key={t}>
                                                <Badge className='tag'>{t}</Badge>
                                                {' '}
                                            </span>
                                        ))}
                                        <br/>
                                    </>
                                }
                                {c.front}
                            </td>
                            <th className={getAnswerTextClass(index)}>{getAnswerLetter(c, index)}</th>
                            <td style={isAnswerVisible(index) ? visibleStyle : hiddenStyle} onClick={() => clickRow(index)} className={isAnswerRevealable(index) ? 'revealable' : ''}>
                                <span className={getAnswerTextClass(index)}>{getBackText(c, index)}</span>
                                {!!c.note &&
                                    <Button style={isRevealed[index] ? buttonStyle : hiddenButtonStyle} onClick={(e) => {showNote(e, c.note);}} className="note-button" variant="secondary">?</Button>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalText}
                </Modal.Body>
                <Modal.Footer>
                    <Button style={buttonStyle} variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Cards;