import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as CardPicker from '../services/CardPicker';
import AppButtons from './AppButtons';
import { ClassroomContext } from '../ClassroomContext';
import { ViewerContext } from './ViewerContext';

function Filter() {
    const { homepage, custom } = useContext(ClassroomContext);
    const [filterCount, setFilterCount] = useState(0);

    const {
        deck, pickCards,
        selectedTags, setSelectedTags,
        cardCount, setCardCount,
        countdown, setCountdown,
        mode, setMode,
        showTags, setShowTags 
    } = useContext(ViewerContext);

    const cards = deck.cards;
    const tagCategories = deck.tagCategories;

    const getFilterCount = useCallback(
        tags => {
            const filteredCards = CardPicker.getCardsMatchingFilter(cards, tagCategories, tags);
            return filteredCards.length;
        },
        [cards, tagCategories]
    );

    useEffect(() => {
        setFilterCount(getFilterCount(selectedTags));
    }, [getFilterCount, selectedTags]);

    const onChange = useCallback(
        event => {
            const id = event.target.id;
            const isChecked = event.target.checked;
            const newSelected = [...selectedTags];
            const index = newSelected.indexOf(id);
            if (index > -1 && !isChecked) {
                newSelected.splice(index, 1);
            } else if (index === -1 && isChecked) {
                newSelected.push(id);
            }
            setSelectedTags(newSelected);
            setFilterCount(getFilterCount(newSelected));
        },
        [selectedTags, setSelectedTags, getFilterCount]
    );

    const onCardCountChange = (event) => {
        setCardCount(parseInt(event.target.value, 10));
    };

    const onCountdownChange = (event) => {
        setCountdown(parseInt(event.target.value, 10));
    };

    const onModeChange = (event) => {
        setMode(event.target.value);
    };

    const onShowTagsChange = (event) => {
        setShowTags(event.target.checked);
    };

    const pl = (singular, plural, count) => {
        return count === 1 ? singular : plural;
    };

    const buttonStyle = {};
    if (custom && custom.buttonColour) {
        buttonStyle.backgroundColor = custom.buttonColour;
        buttonStyle.borderColor = custom.buttonColour;
    }

    return (
        <>
            <div className="toolbar">
                <Link className="btn btn-success" style={buttonStyle} to={homepage}>Back</Link>
                {deck.name && <span className="deck-name">{deck.name}</span>}
                <AppButtons deck={deck} />
            </div>
            <Form>
                <Row>
                    {tagCategories.map(tc => (
                        <Col key={tc.name}>
                            <h4>{tc.name}</h4>
                            {tc.values.map(t => t.name).sort().map(t => (
                                <Form.Check type='checkbox' id={t} key={t} label={t} onChange={onChange} checked={selectedTags.indexOf(t) > -1} data-zero-cards={getFilterCount([...selectedTags, t]) === 0} />
                            ))}
                        </Col>
                    ))}
                    <Col>
                        <p><b>{selectedTags.length}</b> {pl('tag', 'tags', selectedTags.length)} selected, matching <b>{filterCount}</b> {pl('card', 'cards', filterCount)} (out of {cards.length})</p>
                        <Form.Group>
                            <Form.Label>Number of questions</Form.Label>
                            <Form.Control as="select" defaultValue={cardCount} onChange={onCardCountChange}>
                                {[...Array(15).keys()].map(n => (
                                    <option key={n}>{n + 1}</option>
                                ))}
                            </Form.Control>
                            <Form.Group>
                                <div key="inline-radio">
                                    <Form.Check inline label="Q&A" type="radio" name="mode" value="qa" id="qa" checked={mode === 'qa'} onChange={onModeChange} />
                                    <Form.Check inline label="A&Q" type="radio" name="mode" value="aq" id="aq" checked={mode === 'aq'} onChange={onModeChange} />
                                    <Form.Check inline label="Mix" type="radio" name="mode" value="mix" id="mix" checked={mode === 'mix'} onChange={onModeChange} />
                                    <Form.Check inline label="Match" type="radio" name="mode" value="match" id="match" checked={mode === 'match'} onChange={onModeChange} />
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <Form.Check id="show-tags" type="checkbox" label="Show tags" checked={showTags} onChange={onShowTagsChange} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Countdown</Form.Label>
                                <Form.Control as="select" defaultValue={countdown} onChange={onCountdownChange}>
                                    {[...Array(16).keys()].map(n => (
                                        <option value={n} key={n}>{n === 0 ? 'None' : n + ':00'}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                        <Button style={buttonStyle} variant="success" size="lg" onClick={() => pickCards(false)}>Begin</Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default Filter;