import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { ClassroomContext } from '../ClassroomContext';
import PropTypes from 'prop-types';

function DeckPicker() {
    const firstLoad = !localStorage['seen'];
    localStorage['seen'] = 1;
    const [decks, setDecks] = useState(null);
    const [showInstructions, setShowInstructions] = useState(firstLoad);
    const { group, showDrafts, custom } = useContext(ClassroomContext);

    useEffect(() => {
        async function loadIndex() {
            try {
                const response = await fetch(`https://dashboard.remembermore.app/api/classroom/v2/decks/${group}?draft=${showDrafts}`);

                if (response.status !== 200) {
                    console.error(await response.text());
                    return;
                }

                setDecks(await response.json());
            }
            catch (error) {
                console.error(error);
            }
        }
        loadIndex();
    }, [showDrafts, group]);

    const buttonStyle = (deck) => {
        const customColour = deck.colour || custom?.buttonColour;
        if (!customColour) return {};
        return {
            backgroundColor: customColour,
            borderColor: customColour
        };
    };

    const DeckList = ({ decks }) => {
        return decks.map((deck, index) =>
            <DeckLink key={index} deck={deck} />
        );
    };

    const DeckLink = ({ deck }) => {
        return <Link className="btn btn-success" style={buttonStyle(deck)} to={`/${group}/${deck.deckId}`}>{deck.name}</Link>;
    };

    DeckLink.propTypes = {
        deck: PropTypes.shape({
            deckId: PropTypes.string,
            name: PropTypes.string
        })
    };

    // https://stackoverflow.com/a/34890276
    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    const Decks = () => {
        const hasCategories = decks.map(deck => deck.category).filter(cat => cat).length;
        if (hasCategories) {
            for (const deck of decks) {
                if (!deck.category) deck.category = 'Other';
            }
            const categories = groupBy(decks, 'category');
            const result = [];
            for (const category in categories) {
                result.push(
                    <div className="deckCategory mb-3">
                        <h3 key={category}>{category}</h3>
                        <DeckList key={category + '-decks'} decks={categories[category]}/>
                    </div>
                );
            }
            return result;
        }
        else {
            return <DeckList decks={decks}/>;
        }
    };

    return (
        <>
            {showInstructions ?
                <div className="instructions">
                    <img src="/instructions.png" alt="RememberMore Classroom instructions"></img>
                </div> :
                <Button variant="light" onClick={() => setShowInstructions(true)}>Show instructions</Button>}
            <hr />
            {showDrafts &&
                <Alert variant="info">Showing draft decks. All decks will be loaded immediately from Google Sheets.</Alert>
            }
            <h2>Choose a deck</h2>
            {decks === null ?
                <p>Loading...</p>
                :
                <div className="decks">
                    <Decks />
                </div>
            }
        </>
    );
}

export default DeckPicker;