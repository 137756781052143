import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import Classroom from './components/Classroom';
import PropTypes from 'prop-types';

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Classroom group='classroom' />
                </Route>
                <Route path="/:id">
                    <Custom />
                </Route>
            </Switch>
        </Router>
    );
}

function Custom() {
    const [error, setError] = useState(null);
    const [customHomepage, setCustomHomepage] = useState(null);
    let { id } = useParams();

    useEffect(() => {
        async function getCustomHomepages() {
            if (id === 'classroom') {
                setCustomHomepage({
                    title: 'classroom.remembermore.app'
                });
            } else {
                try {
                    const response = await fetch('https://dashboard.remembermore.app/api/classroom/settings/' + id);
                    
                    if (response.status !== 200) {
                        setError(await response.text());
                        return;
                    }
            
                    setCustomHomepage(await response.json());
                }
                catch (error) {
                    setError(error);
                }
            }
        }
        getCustomHomepages();
    }, [id]);

    if (error) {
        return (
            <p>{error}</p>
        );
    }

    if (customHomepage === null) return null;
  
    return (
        <Classroom custom={customHomepage} group={id} />
    );
}

export default hot(module)(App);

Custom.propTypes = {
    showDrafts: PropTypes.bool
};