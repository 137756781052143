export function getCardsMatchingFilter(cards, tagCategories, selectedTagNames) {
    let filteredCards = cards;

    if (selectedTagNames.length) {
        for (const tagCategory of tagCategories) {
            const tagCategoryTagNames = tagCategory.values.map(t => t.name);
            const selectedTagNamesInThisCategory = selectedTagNames.filter(t => tagCategoryTagNames.indexOf(t) > -1);
            if (selectedTagNamesInThisCategory.length) {
                filteredCards = filteredCards.filter(c => c.tags.some(t => selectedTagNamesInThisCategory.indexOf(t) > -1));
            }
        }
    }

    return filteredCards;
}

// https://stackoverflow.com/questions/11935175/sampling-a-random-subset-from-an-array
function getRandomSubarray(arr, size) {
    var shuffled = arr.slice(0), i = arr.length, min = i - size, temp, index;
    while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
    }
    return shuffled.slice(min);
}

export function pickCards(cards, tagCategories, selectedTagNames, pickCount) {
    const filteredCards = getCardsMatchingFilter(cards, tagCategories, selectedTagNames);
    return getRandomSubarray(filteredCards, Math.min(pickCount, filteredCards.length));
}

export function addShuffledBacks(cards) {
    const shuffledIndexes = getRandomSubarray([...Array(cards.length).keys()], cards.length);
    const result = [];
    for (let i = 0; i < cards.length; i++) {
        const card = {...cards[i]};
        card.shuffledBack = cards[shuffledIndexes[i]].back;
        card.shuffledBackIndex = shuffledIndexes[i];
        result.push(card);
    }
    for (let i = 0; i < result.length; i++) {
        result[result[i].shuffledBackIndex].answerIndex = i;
    }
    return result;
}