import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import AppButtons from './AppButtons';
import Countdown from './Countdown';
import { ClassroomContext } from '../ClassroomContext';
import { ViewerContext } from './ViewerContext';

export default function ViewerToolbar() {
    const { custom } = useContext(ClassroomContext);

    const {
        deck, pickCards,
        countdown,
        filteredCards, setFilteredCards,
        isRevealed, setIsRevealed,
        startTime
    } = useContext(ViewerContext);

    const unsetFilter = () => {
        setFilteredCards(null);
    };

    const reveal = () => {
        const newValue = !isRevealed.every(a => a);
        setIsRevealed(Array(filteredCards.length).fill(newValue));
    };

    const fullscreenEnabled = !!document.documentElement.requestFullscreen;

    const fullscreen = () => {
        document.documentElement.requestFullscreen();
    };

    const print = () => {
        window.print();
    };

    const buttonStyle = {};
    if (custom && custom.buttonColour) {
        buttonStyle.backgroundColor = custom.buttonColour;
        buttonStyle.borderColor = custom.buttonColour;
    }

    return (
        <div className="toolbar d-print-none">
            <Button style={buttonStyle} variant="success" onClick={unsetFilter}>Back</Button>
            <Button style={buttonStyle} variant="success" onClick={reveal}>Reveal</Button>
            <Button style={buttonStyle} variant="success" onClick={() => pickCards(true)}>Reorder</Button>
            <Button style={buttonStyle} variant="success" onClick={() => pickCards(false)}>Refresh</Button>
            {fullscreenEnabled &&
                <Button style={buttonStyle} variant="success" onClick={fullscreen}>Full-screen</Button>
            }
            <Button style={buttonStyle} variant="success" onClick={print}>Print</Button>
            <AppButtons deck={deck} />
            {countdown > 0 &&
                <Countdown initialMinutes={countdown} startTime={startTime} />
            }
        </div>
    );
}