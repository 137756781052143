import React from 'react';
import ReactDOM from 'react-dom';
import './App.scss';
import App from './App';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const isDev = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

if (!isDev) {
    Sentry.init({
        dsn: 'https://52d5e28c45ce43ef8e944191918d2082@o430289.ingest.sentry.io/5663997',
        integrations: [new Integrations.BrowserTracing()],
    
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.1,
    });
}

ReactDOM.render(
    <App />,
    document.getElementById('root')
);
