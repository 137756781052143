import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function formatMMSS(secs) {
    // https://stackoverflow.com/a/34841026/31280
    const sec_num = parseInt(secs, 10);
    const minutes = Math.floor(sec_num / 60);
    const seconds = sec_num % 60;

    return [minutes, seconds]
        .map(v => v < 10 ? '0' + v : v)
        .join(':');
}

export default function Countdown({ initialMinutes, startTime }) {
    const resolution = 100;
    const [elapsedTime, setElapsedTime] = useState(1);

    useEffect(() => {
        let myTimeout = setTimeout(() => {
            const newElapsedTime = new Date() - startTime;
            setElapsedTime(newElapsedTime);
        }, resolution);
        return () => {
            clearTimeout(myTimeout);
        };
    });

    let timeRemaining = (initialMinutes * 60 * 1000) + 1000 - elapsedTime;
    if (timeRemaining <= 0) {
        timeRemaining = 0;
    }

    return (<p className="clock clockText">
        { formatMMSS(timeRemaining / 1000) }
    </p>);
}

Countdown.propTypes = {
    initialMinutes: PropTypes.number,
    startTime: PropTypes.instanceOf(Date)
};