import React from 'react';
import PropTypes from 'prop-types';

export default function AppButtons({ deck }) {
    return (
        <>
            {deck.iOS &&
                <a href={deck.iOS} target="_blank" rel="noopener noreferrer">
                    <img className="app" alt="Download on the App Store" src="/app-store-badge.png"></img>
                </a>
            }
            {deck.android &&
                <a href={deck.android} target="_blank" rel="noopener noreferrer">
                    <img className="app" alt="Get it on Google Play" src="/google-play-badge.png"></img>
                </a>
            }
        </>
    );
}

AppButtons.propTypes = {
    deck: PropTypes.object
};