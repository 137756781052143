import React from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

function RemembermoreNavbar({ custom, group }) {
    const logoUrl = custom?.logoUrl || '/logofirework.png';

    return (
        <Navbar bg="primary" variant="dark" expand="lg" ref={(element) => {
            if (element && custom && custom.navColour) {
                element.style.setProperty('background-color', custom.navColour, 'important');
            }
        }}>
            <Container fluid>
                <Navbar.Brand as={Link} to={group === 'classroom' ? '/' : `/${group}`}>
                    <img src={logoUrl} alt='logo' />
                    {custom?.title || 'classroom.remembermore.app'}
                </Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="https://www.remembermore.app/home" target="_blank">RememberMore</Nav.Link>
                        {group === 'classroom' &&
                            <>
                                <Nav.Link href="https://docs.google.com/forms/d/e/1FAIpQLSdB0T3jp1ga9Zu1iH6JKIjjtUHOJRKg_PCV-AkmLFZlxBoPig/viewform?usp=sf_link" target="_blank">Feedback</Nav.Link>
                                <Nav.Link href="https://docs.google.com/forms/d/e/1FAIpQLSefPTPPDjDtJqpb2sE_zwckLwDUFuTfD9TBABxi2wfsTFagPg/viewform?usp=sf_link" target="_blank">Submit a Question</Nav.Link>
                            </>
                        }
                        <NavDropdown title="Mobile Apps">
                            <NavDropdown.Item href="https://apps.apple.com/us/app/remembermore/id1554666333" target="_blank">iOS - App Store</NavDropdown.Item>
                            <NavDropdown.Item href="https://play.google.com/store/apps/details?id=app.remembermore.shell" target="_blank">Android - Google Play</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </Container>
        </Navbar>
    );
}

RemembermoreNavbar.propTypes = {
    custom: PropTypes.object,
    group: PropTypes.string
};

export default RemembermoreNavbar;