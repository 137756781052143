import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Navbar from './Navbar';
import DeckPicker from './DeckPicker';
import DeckLoader from './DeckLoader';
import { ClassroomContext } from '../ClassroomContext';

function Classroom({ custom, group }) {
    const match = useRouteMatch();
    const query = new URLSearchParams(window.location.search);
    const showDrafts = !!query.get('draft');

    const homepage = '/' + (group === 'classroom' ? '' : group);

    const context = {
        showDrafts,
        group,
        homepage,
        custom
    };

    return (
        <ClassroomContext.Provider value={context}>
            <Navbar custom={custom} group={group} />
            <Container fluid style={{marginTop: '10px'}}>
                <Switch>
                    <Route path={`${match.path}/:deckId`}>
                        <DeckLoader />
                    </Route>
                    <Route path={match.path}>
                        <DeckPicker />
                    </Route>
                </Switch>
            </Container>
        </ClassroomContext.Provider>
    );
}

Classroom.propTypes = {
    custom: PropTypes.object,
    group: PropTypes.string
};

export default Classroom;