import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Filter from './Filter';
import Cards from './Cards';
import * as CardPicker from '../services/CardPicker';
import { ViewerContext } from './ViewerContext';

function Viewer({ deck }) {
    const [selectedTags, setSelectedTags] = useState([]);
    const [filteredCards, setFilteredCards] = useState(null);
    const [isRevealed, setIsRevealed] = useState(null);
    const [showQuestionFirst, setShowQuestionFirst] = useState(null);
    const [cardCount, setCardCount] = useState(10);
    const [countdown, setCountdown] = useState(10);
    const [mode, setMode] = useState('qa');
    const [showTags, setShowTags] = useState(true);
    const [startTime, setStartTime] = useState(new Date());

    const pickCards = (fromExisting) => {
        let newFilteredCards = CardPicker.pickCards(fromExisting ? filteredCards : deck.cards, deck.tagCategories, selectedTags, cardCount);
        setIsRevealed(Array(newFilteredCards.length).fill(false));
        let array = null;
        if (mode === 'mix') {
            array = Array.from({length: newFilteredCards.length}, () => Math.random() < 0.5);
        } else if (mode === 'qa' || mode === 'aq') {
            array = Array(newFilteredCards.length).fill(mode === 'qa');
        } else if (mode === 'match') {
            newFilteredCards = CardPicker.addShuffledBacks(newFilteredCards);
        }
        setShowQuestionFirst(array);
        setFilteredCards(newFilteredCards);
        setCountdown(countdown);
        setStartTime(new Date());
    };

    const context = {
        deck, pickCards,
        selectedTags, setSelectedTags,
        cardCount, setCardCount,
        countdown, setCountdown,
        mode, setMode,
        showTags, setShowTags,
        filteredCards, setFilteredCards,
        isRevealed, setIsRevealed,
        showQuestionFirst,
        startTime
    };

    return (
        <ViewerContext.Provider value={context}>
            {filteredCards === null ?
                <Filter />
                :
                <Cards />
            }
        </ViewerContext.Provider>
    );
}

Viewer.propTypes = {
    deck: PropTypes.object
};

export default Viewer;