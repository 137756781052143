import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Viewer from './Viewer';
import { ClassroomContext } from '../ClassroomContext';

function DeckLoader() {
    const { deckId } = useParams();
    const [ deck, setDeck ] = useState(null);
    const [ error, setError ] = useState(null);
    const { showDrafts, group } = useContext(ClassroomContext);

    useEffect(() => {
        async function doImport() {
            try {
                let url = 'https://dashboard.remembermore.app/api/classroom/v2/get-deck/' + deckId;
                if (showDrafts) url += '?nocache=true';
                const response = await fetch(url);
                
                if (response.status !== 200) {
                    setError(await response.text());
                    return;
                }

                const deck = await response.json();
        
                setDeck({
                    ...deck,
                    deckId: deckId,
                    iOS: getiOSLink(group, deck),
                    android: getAndroidLink(group, deck),
                });
            }
            catch (error) {
                setError(error);
            }
        }
        doImport();
    }, [deckId, showDrafts, group]);

    if (error) {
        return (
            <p>{error}</p>
        );
    } else if (deck) {
        return (
            <Viewer deck={deck} />
        );
    } else {
        return (
            <p>Loading...</p>
        );
    }
}

function getiOSLink(group, deck) {
    if (group !== 'classroom') return 'https://apps.apple.com/us/app/remembermore/id1554666333';
    if (deck.iOS) return deck.iOS;
    return null;
}

function getAndroidLink(group, deck) {
    if (group !== 'classroom') return 'https://play.google.com/store/apps/details?id=app.remembermore.shell';
    if (deck.android) return deck.android;
    return null;
}

export default DeckLoader;
